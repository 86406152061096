// src/redux/reducers/index.ts
import { combineReducers } from 'redux';
import portfolioReducer from './portfolioReducer';
import authReducer from './authReducer';
import contactReducer from './contactReducer';
import skillReducer from './skillReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    portfolio: portfolioReducer,
    contact: contactReducer,
    skills: skillReducer
});

export default rootReducer;
