import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendMessage } from '../redux/actions/contactActions';
import { RootState } from '../redux/store';
import ReCAPTCHA from 'react-google-recaptcha';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/Contact.scss';
import { useTranslation } from 'react-i18next';

const Contact: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({ name: '', email: '', subject: '', details: '' });
    const { loading, error, success } = useSelector((state: RootState) => state.contact);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

    const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (recaptchaToken) {
            dispatch(sendMessage({ ...formData, recaptchaToken }));
            setFormData({ name: '', email: '', subject: '', details: '' });
            recaptchaRef.current?.reset();
        } else {
            toast.error('Please complete the reCAPTCHA');
        }
    };

    useEffect(() => {
        if (success) {
            toast.success('Message sent successfully!');
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(`Error sending message: ${error}`);
        }
    }, [error]);

    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea) {
            const adjustHeight = () => {
                textarea.style.height = '150px';
                textarea.style.height = `${textarea.scrollHeight}px`;
            };

            textarea.addEventListener('input', adjustHeight);

            // Initial adjustment
            adjustHeight();

            return () => {
                textarea.removeEventListener('input', adjustHeight);
            };
        }
    }, []);

    return (
        <section className='contact_section' id='contact'>
            <h2>Contact</h2>
            <section className='email_and_phone'>
                <article className='phone_article'>
                    <a href="tel:+33 7 66 03 01 02" id='phone_icon'></a>
                    <div className='article_content'>
                        <h3 className='article_title'>
                            {t('contact_phone')}
                        </h3>
                        <a className='article_link' href="tel:+33 7 66 03 01 02">+33 7 66 03 01 02</a>
                    </div>
                </article>

                <article className='email_article'>
                    <a href="mailto:contact@neodev3d.com" id='email_icon'></a>
                    <div className='article_content'>
                        <h3 className='article_title'>
                            {t('contact_email')}
                        </h3>
                        <a className='article_link' href="mailto:contact@neodev3d.com">contact@neodev3d.com</a>
                    </div>
                </article>
            </section>
            <form className='contact_form' onSubmit={handleSubmit}>
                <h3>{t('contact_form')}</h3>
                <label>{t('form_name')}</label>
                <input type="text" name="name" placeholder={t('form_name_details')} value={formData.name} onChange={handleChange} required />
                <label>{t('form_email')}</label>
                <input type="email" name="email" placeholder={t('form_email_details')} value={formData.email} onChange={handleChange} required />
                <label>{t('form_subject')}</label>
                <input type="text" name="subject" placeholder={t('form_subject_details')} value={formData.subject} onChange={handleChange} required />
                <label>{t('form_message')}</label>
                <textarea
                    ref={textareaRef}
                    name="details"
                    placeholder={t('form_message_details')}
                    value={formData.details}
                    onChange={handleChange}
                    required
                    style={{ resize: 'none', overflow: 'hidden' }}
                ></textarea>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={reCaptchaKey || ''}
                    onChange={(token) => setRecaptchaToken(token)}
                />
                <button type="submit" disabled={loading}>{t('form_send')}</button>
            </form>
        </section>
    );
};

export default Contact;
