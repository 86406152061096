import React from "react";
import "../../styles/Dashboard.scss";

const DashBoard = () => {
  return (
    <div className="dashboard_container">
      <div className="dashboard_content">
        <section className="statistics">
          <div className="stat_card">
            <p>xxxx</p>
            <p>Visites</p>
          </div>
          <div className="stat_card">
            <p>xxxx</p>
            <p>Messages</p>
          </div>
          <div className="stat_card">
            <p>xxxx</p>
            <p>Projets</p>
          </div>
          <div className="stat_card">
            <p>xxxx</p>
            <p>Témoignages</p>
          </div>
        </section>
        <section className="chart">
          <h2>Graphique de statistiques</h2>
          {/* Placez ici votre composant graphique */}
        </section>
      </div>
    </div>
  );
};

export default DashBoard;