// src/components/ProjectCard.tsx
import React from 'react';
import { Project } from '../redux/types/types';

interface ProjectCardProps {
    project: Project;
    onClick: (project: Project) => void;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ project, onClick }) => {
    return (
        <div className="project-card" onClick={() => onClick(project)}>
            <h3 className='project_title'>{project.title}</h3>
            {project.images && project.images.length > 0 && (
                <img src={project.images[0]} alt={project.title} />
            )}
        </div>
    );
};

export default ProjectCard;
