// reducers/authReducer.ts
import { AnyAction } from 'redux';

interface AuthState {
    isAuthenticated: boolean;
    loading: boolean;
    error: string | null;
    token: string | null;
}

const initialState: AuthState = {
    isAuthenticated: false,
    loading: false,
    error: null,
    token: null,
};

const authReducer = (state = initialState, action: AnyAction): AuthState => {
    switch (action.type) {
        case 'LOGIN_REQUEST':
            return { ...state, loading: true, error: null };
        case 'LOGIN_SUCCESS':
            return { ...state, loading: false, isAuthenticated: true, token: action.payload.token };
        case 'LOGIN_FAILURE':
            return { ...state, loading: false, error: action.payload };
        case 'LOGOUT':
            return initialState;
        default:
            return state;
    }
};

export default authReducer;
