// src/components/AdminMessageItem.tsx
import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteMessage } from '../../redux/actions/contactActions';

interface AdminMessageItemProps {
    message: any;
}

const AdminMessageItem: React.FC<AdminMessageItemProps> = ({ message }) => {
    const dispatch = useDispatch();

    const handleDelete = () => {
        dispatch(deleteMessage(message.id));
    };

    return (
        <div className="message-item">
            <h3>{message.subject}</h3>
            <p><strong>Name:</strong> {message.name}</p>
            <p><strong>Email:</strong> {message.email}</p>
            <p><strong>Date:</strong> {new Date(message.date).toLocaleString()}</p>
            <p><strong>Content:</strong> {message.content}</p>
            <button onClick={handleDelete}>Delete</button>
        </div>
    );
};

export default AdminMessageItem;
