import React, { useRef, useEffect } from 'react';
import { Project } from '../redux/types/types';
import ThreeView from './ThreeView';
import FpsView from './FpsView'; // Assuming you have an FpsView component
import { PhotoProvider, PhotoView } from 'react-photo-view';

interface ProjectModalProps {
    project: Project;
    onClose: () => void;
    children?: React.ReactNode;
}

const ProjectModal: React.FC<ProjectModalProps> = ({ project, onClose, children }) => {
    const modalContentRef = useRef<HTMLDivElement>(null);
    const modelPath = `/models/${project.model}`;
    const isLargeImage = !project.model || project.model === 'Private model';
    const isFpsModel = project.model && project.model.includes('FPS');

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalContentRef.current && !modalContentRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <div className={`modal ${project ? 'is-open' : ''}`}>
            <div className="modal-content" ref={modalContentRef}>
                <span className="close-button" onClick={onClose}>
                    &times;
                </span>
                <h2>{project.title}</h2>
                <p>{project.description}</p>
                <div className={`image-gallery ${isLargeImage ? 'web-project' : ''}`}>
                    <PhotoProvider>
                        {project.images.map((image, index) => (
                            <PhotoView key={index} src={image}>
                                <img
                                    src={image}
                                    alt={`${project.title} - ${index + 1}`}
                                    className={isLargeImage ? 'large-image' : ''}
                                />
                            </PhotoView>
                        ))}
                    </PhotoProvider>
                </div>
                {project.model && project.model !== 'Private model' && (
                    <div style={{ height: '100%' }}>
                        <p>3D Model : {project.model}</p>
                        {isFpsModel ? (
                            <FpsView modelPath={modelPath} />
                        ) : (
                            <ThreeView modelPath={modelPath} />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProjectModal;
