// src/components/SkillModal.tsx
import React, { useState, useEffect } from 'react';

interface SkillModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (skillData: any) => void;
    onDelete: (id: number) => void;
    skill?: any;
}

const SkillModal: React.FC<SkillModalProps> = ({ isOpen, onClose, onSubmit, onDelete, skill }) => {
    const [skillName, setSkillName] = useState('');
    const [skillDescription, setSkillDescription] = useState('');
    const [is3DSkill, setIs3DSkill] = useState(false);

    useEffect(() => {
        if (skill) {
            setSkillName(skill.name);
            setSkillDescription(skill.description || '');
            setIs3DSkill(skill.is_3d_skill);
        } else {
            setSkillName('');
            setSkillDescription('');
            setIs3DSkill(false);
        }
    }, [skill]);

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const skillData = { name: skillName, description: skillDescription, is_3d_skill: is3DSkill };
        onSubmit(skillData);
        onClose();
    };

    const handleDelete = () => {
        if (skill) {
            onDelete(skill.id);
            onClose();
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal is-open">
            <div className="modal-content">
                <button className="modal-close" onClick={onClose}>&times;</button>
                <h2>{skill ? 'Edit Skill' : 'Add Skill'}</h2>
                <form onSubmit={handleFormSubmit}>
                    <label>Skill Name</label>
                    <input
                        type="text"
                        value={skillName}
                        onChange={(e) => setSkillName(e.target.value)}
                        placeholder="Skill Name"
                        required
                    />
                    <label>Skill Description</label>
                    <textarea
                        value={skillDescription}
                        onChange={(e) => setSkillDescription(e.target.value)}
                        placeholder="Skill Description"
                        required
                    ></textarea>
                    <label>Is 3D Skill</label>
                    <input
                        type="checkbox"
                        checked={is3DSkill}
                        onChange={(e) => setIs3DSkill(e.target.checked)}
                    />
                    <button type="submit">{skill ? 'Update Skill' : 'Add Skill'}</button>
                </form>
                {skill && <button className="delete-button" onClick={handleDelete}>Delete Skill</button>}
            </div>
        </div>
    );
};

export default SkillModal;
