// src/components/AdminUpdateInfo.tsx
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSkills, createSkill, updateSkill, deleteSkill } from "../../redux/actions/skillActions";
import SkillModal from "./SkillModal";
import '../../styles/AdminUpdateInfo.scss';

const AdminUpdateInfo: React.FC = () => {
    const dispatch = useDispatch();
    const skills = useSelector((state: any) => state.skills.skills);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalType, setModalType] = useState<'create' | 'edit'>('create');
    const [selectedSkill, setSelectedSkill] = useState<any>(null);

    useEffect(() => {
        dispatch(fetchSkills());
    }, [dispatch]);

    const openModal = (type: 'create' | 'edit', skill?: any) => {
        setModalType(type);
        if (type === 'edit' && skill) {
            setSelectedSkill(skill);
        } else {
            setSelectedSkill(null);
        }
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleFormSubmit = (skillData: any) => {
        if (modalType === 'create') {
            dispatch(createSkill(skillData));
        } else if (modalType === 'edit' && selectedSkill) {
            dispatch(updateSkill(selectedSkill.id, skillData));
        }
    };

    const handleDelete = (id: number) => {
        dispatch(deleteSkill(id));
    };

    return (
        <div className="update-info">
            <section className="update-content">
                <div className="update-form">
                    <h2>Skills</h2>
                    <button onClick={() => openModal('create')}>Add Skill</button>
                    <ul>
                        {skills.map((skill: any) => (
                            <li key={skill.id}>
                                {skill.name} ({skill.is_3d_skill ? '3D' : 'Dev'})
                                <button onClick={() => openModal('edit', skill)}>Edit</button>
                            </li>
                        ))}
                    </ul>
                </div>
            </section>

            <SkillModal 
                isOpen={modalIsOpen} 
                onClose={closeModal} 
                onSubmit={handleFormSubmit} 
                onDelete={handleDelete} 
                skill={selectedSkill} 
            />
        </div>
    );
};

export default AdminUpdateInfo;
