import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Home from './components/Home';
import Portfolio from './components/Portfolio';
import Terms from './components/Terms';
import LoginForm from './components/Login';
import AdminPanel from './components/Dashboard/AdminPanel';
import PrivateRoute from './components/PrivateRoute';
import Printing from './components/Printing';
import { validateToken } from './redux/actions/authActions';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react";
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import './styles/Base.scss';
import { ToastContainer } from 'react-toastify';
import './styles/ReactToastify.scss';
import 'react-photo-view/dist/react-photo-view.css';



const App: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!Cookies.get('sessionId')) {
            const sessionId = uuidv4();
            Cookies.set('sessionId', sessionId, { expires: 0.5, sameSite: 'Strict' }); // 0.5 days is 12 hours
        }
        dispatch(validateToken());
    }, [dispatch]);

    return (
        <React.Fragment>
            <ToastContainer
                position="bottom-center"
                autoClose={6000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                stacked
            />
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/portfolio" element={<Portfolio />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/login" element={<LoginForm />} />
                    <Route element={<PrivateRoute />}>
                        <Route path="/admin-panel" element={<AdminPanel />} />
                    </Route>
                    <Route path ="/3d_printing" element={<Printing />} />
                </Routes>
                <Analytics />
            </Router>
            <SpeedInsights />
        </React.Fragment>
    );
};

export default App;
