import React, { useEffect } from 'react';

import About from './About';
import Portfolio from './Portfolio';
import Services from './Services';
import Contact from './Contact';

import WIP from './WIP';

import Header from './Header';
import Footer from './Footer';

import '../styles/Home.scss';

import { useTranslation } from 'react-i18next';

import { useLocation } from 'react-router-dom';

import { scroller } from 'react-scroll';

import Popup3D from './Popup3dPrinting'; // Importer la popup

import '../styles/Popup3dPrinting.scss'; 

const MIN_SPEED = 1;
const MAX_SPEED = 2.5;

function randomNumber(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

class Blob {
  el: HTMLElement;
  size: number;
  initialX: number;
  initialY: number;
  vx: number;
  vy: number;
  x: number;
  y: number;

  constructor(el: HTMLElement) {
    this.el = el;
    const boundingRect = this.el.getBoundingClientRect();
    this.size = boundingRect.width;
    this.initialX = randomNumber(0, window.innerWidth - this.size);
    this.initialY = randomNumber(0, window.innerHeight - this.size);
    this.el.style.top = `${this.initialY}px`;
    this.el.style.left = `${this.initialX}px`;
    this.vx = randomNumber(MIN_SPEED, MAX_SPEED) * (Math.random() > 0.5 ? 1 : -1);
    this.vy = randomNumber(MIN_SPEED, MAX_SPEED) * (Math.random() > 0.5 ? 1 : -1);
    this.x = this.initialX;
    this.y = this.initialY;
  }

  update() {
    this.x += this.vx;
    this.y += this.vy;
    if (this.x >= window.innerWidth - this.size) {
      this.x = window.innerWidth - this.size;
      this.vx *= -1;
    }
    if (this.y >= window.innerHeight - this.size) {
      this.y = window.innerHeight - this.size;
      this.vy *= -1;
    }
    if (this.x <= 0) {
      this.x = 0;
      this.vx *= -1;
    }
    if (this.y <= 0) {
      this.y = 0;
      this.vy *= -1;
    }
  }

  move() {
    this.el.style.transform = `translate(${this.x - this.initialX}px, ${this.y - this.initialY}px)`;
  }
}

function initBlobs() {
  const blobEls = document.querySelectorAll('.bouncing-blob');
  const blobs = Array.from(blobEls).map((blobEl) => new Blob(blobEl as HTMLElement));

  function update() {
    requestAnimationFrame(update);
    blobs.forEach((blob) => {
      blob.update();
      blob.move();
    });
  }

  requestAnimationFrame(update);
}

const Home: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    initBlobs();
  }, []);

  useEffect(() => {
    if (location.state?.scrollToContact) {
      scroller.scrollTo('contact_section', {
        duration: 500,
        delay: 500,
        smooth: 'easeInOutQuart',
      });
    }
  }, [location]);

  return (
    <React.Fragment>
      <div className="bouncing-blobs-container">
        <div className="bouncing-blobs-glass"></div>
        <div className="bouncing-blobs">
          <div className="bouncing-blob bouncing-blob--blue"></div>
          <div className="bouncing-blob bouncing-blob--blue"></div>
          <div className="bouncing-blob bouncing-blob--blue"></div>
          <div className="bouncing-blob bouncing-blob--white"></div>
          <div className="bouncing-blob bouncing-blob--purple"></div>
          <div className="bouncing-blob bouncing-blob--purple"></div>
          <div className="bouncing-blob bouncing-blob--pink"></div>
        </div>
      </div>
      <Header />
      <section className='welcome_section' id='home'>
        <main className='welcome_container'>
          <div className='welcome_section_01'>
            <a target="_blank" href="https://www.linkedin.com/in/arthur-clapasson" rel="noreferrer" className='profile_picture'></a>
          </div>
          <div className='welcome_section_02'>
          {t('welcome_txt')}
          </div>
        </main>
      </section>
      <About />
      <Portfolio />
      <Services />
      <Contact />
      <Popup3D />
      <Footer />
      <div className='footer_space'/>
    </React.Fragment>
  );
};

export default Home;
