// src/components/Services.tsx
import React, { useState } from 'react';
import '../styles/Services.scss';

const Services: React.FC = () => {
  const [hoveredService, setHoveredService] = useState<string | null>(null);

  return (
    <div className="services">
      <h2>Services</h2>
      <div className='services_box'>
        <section className="services_container">
          <div
            className="service-item"
            onMouseEnter={() => setHoveredService('Web Design')}
            onMouseLeave={() => setHoveredService(null)}
          >
            <i></i>
            <svg xmlns="http://www.w3.org/2000/svg" width="undefined" height="undefined" viewBox="0 0 24 24" className="design_icon">
              <path fill="currentColor" d="M21.97 8.17H2.03A6.76 6.76 0 0 1 8.75 2h6.5a6.76 6.76 0 0 1 6.72 6.17m-13.8 2v11.8A6.76 6.76 0 0 1 2 15.25v-5.08zm13.83 0v5.08A6.76 6.76 0 0 1 15.25 22h-5.08V10.17z"/>
            </svg>
            <h3>Web Design</h3>
            {hoveredService === 'Web Design' && (
              <ul className="service-details">
                <li>Responsive Design</li>
                <li>UI/UX Design</li>
                <li>Prototyping</li>
              </ul>
            )}
          </div>
          <div
            className="service-item"
            onMouseEnter={() => setHoveredService('Development')}
            onMouseLeave={() => setHoveredService(null)}
          >
            <i></i>
            <svg xmlns="http://www.w3.org/2000/svg" width="undefined" height="undefined" viewBox="0 0 24 24" className="dev_icon">
              <path fill="currentColor" d="M17.53 13.04h-3a1 1 0 1 1 0-2h3a1 1 0 0 1 0 2"/>
              <path fill="currentColor" d="M21.73 7.98V4.52a1.86 1.86 0 0 0-.56-1.34a1.89 1.89 0 0 0-1.34-.56H4.15a1.88 1.88 0 0 0-1.34.56a1.86 1.86 0 0 0-.55 1.31v3.5a1.87 1.87 0 0 1 0 2.27v3.46a1.85 1.85 0 0 1 0 2.3v3.46a1.91 1.91 0 0 0 1.9 1.9h15.68a1.91 1.91 0 0 0 1.9-1.9v-3.46a1.85 1.85 0 0 1 0-2.3v-3.41a1.82 1.82 0 0 0-.41-1.16a1.84 1.84 0 0 0 .4-1.17m-7.22-2.71h3a1 1 0 0 1 0 2h-3a1 1 0 1 1 0-2m3 13.53h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2m2.69-5a.4.4 0 0 1-.4.4H4.14a.4.4 0 0 1-.4-.4v-3.49a.39.39 0 0 1 .34-.39h.07l15.49-.05h.18a.4.4 0 0 1 .4.4z"/>
            </svg>
            <h3>Development</h3>
            {hoveredService === 'Development' && (
              <ul className="service-details">
                <li>Frontend Development</li>
                <li>Backend Development</li>
                <li>API Integration</li>
              </ul>
            )}
          </div>
          <div
            className="service-item"
            onMouseEnter={() => setHoveredService('Maintenance')}
            onMouseLeave={() => setHoveredService(null)}
          >
            <i></i>
            <svg xmlns="http://www.w3.org/2000/svg" width="undefined" height="undefined" viewBox="0 0 24 24" className='maintenance_icon'>
              <path fill="currentColor" d="M21.763 11.382a7.573 7.573 0 0 1-3.47 4.693a7.56 7.56 0 0 1-5.772.827a1.27 1.27 0 0 0-.67 0a1.178 1.178 0 0 0-.57.31l-4.266 4.29a1.88 1.88 0 0 1-.56.37a1.683 1.683 0 0 1-.669.13a1.648 1.648 0 0 1-.659-.13a1.759 1.759 0 0 1-.56-.37L2.5 19.432a1.61 1.61 0 0 1-.37-.56a1.771 1.771 0 0 1 0-1.33a1.61 1.61 0 0 1 .37-.56l4.277-4.28a1.17 1.17 0 0 0 .32-.56c.06-.209.06-.43 0-.64a7.586 7.586 0 0 1 2.117-7.42a7.522 7.522 0 0 1 3.497-1.88a7.428 7.428 0 0 1 3.997.15a.74.74 0 0 1 .31 1.24L14.1 6.522a2.131 2.131 0 0 0-.56 1.41a.91.91 0 0 0 .21.63l1.719 1.73a1.1 1.1 0 0 0 .91.18a2.128 2.128 0 0 0 1.138-.53l2.918-2.93a.78.78 0 0 1 .71-.2a.749.749 0 0 1 .539.51a7.606 7.606 0 0 1 .08 4.06"/>
            </svg>
            <h3>Maintenance</h3>
            {hoveredService === 'Maintenance' && (
              <ul className="service-details">
                <li>Regular Updates</li>
                <li>Backup Services</li>
              </ul>
            )}
          </div>
          <div
            className="service-item"
            onMouseEnter={() => setHoveredService('3D Modeling')}
            onMouseLeave={() => setHoveredService(null)}
          >
            <i></i>
            <svg xmlns="http://www.w3.org/2000/svg" width="undefined" height="undefined" viewBox="0 0 24 24" className="modeling_icon">
              <path fill="currentColor" d="M15.25 2.02h-6.5A6.76 6.76 0 0 0 2 8.77v6.46a6.76 6.76 0 0 0 6.75 6.75h6.5A6.76 6.76 0 0 0 22 15.23v-6.5a6.76 6.76 0 0 0-6.75-6.71m1.64 12.83a.94.94 0 0 1-.36.38l-3.84 2.29v-5.23l4.33-2.61v4.67a1 1 0 0 1-.13.5m-9.79 0a1 1 0 0 1-.14-.51V9.67l4.34 2.6v5.26l-3.84-2.31a1 1 0 0 1-.36-.36zm4.91-8.57a1 1 0 0 1 .54.16l3.81 2L12 11.06L7.63 8.44l3.84-2a1 1 0 0 1 .54-.15z"/>
            </svg>
            <h3>3D Modeling</h3>
            {hoveredService === '3D Modeling' && (
              <ul className="service-details">
                <li>Asset Modeling</li>
                <li>Environment Modeling</li>
                <li>Product Visualization</li>
                <li>Architectural Visualization</li>
              </ul>
            )}
          </div>
          <div
            className="service-item"
            onMouseEnter={() => setHoveredService('Texturing')}
            onMouseLeave={() => setHoveredService(null)}
          >
            <i></i>
            <svg xmlns="http://www.w3.org/2000/svg" width="undefined" height="undefined" viewBox="0 0 24 24" className="texturing_icon">
              <path fill="currentColor" d="M21.738 16.13a1 1 0 0 1-.19.61a1 1 0 0 1-.52.38l-1.71.57a3.57 3.57 0 0 0-1.4.86a3.5 3.5 0 0 0-.86 1.4l-.6 1.7a1 1 0 0 1-.36.51a1.08 1.08 0 0 1-.62.19a1 1 0 0 1-1-.71l-.57-1.71a3.5 3.5 0 0 0-.86-1.4a3.789 3.789 0 0 0-1.4-.87l-1.71-.56a1.11 1.11 0 0 1-.51-.37a1.08 1.08 0 0 1-.21-.62a1 1 0 0 1 .71-1l1.72-.57a3.54 3.54 0 0 0 2.28-2.28l.57-1.69a1 1 0 0 1 .95-.73c.215 0 .426.059.61.17c.182.125.322.303.4.51l.58 1.74a3.54 3.54 0 0 0 2.28 2.28l1.7.6a1 1 0 0 1 .51.38a1 1 0 0 1 .21.61m-9.999-6.36a1 1 0 0 1-.17.55a1 1 0 0 1-.47.35l-1.26.42c-.353.122-.673.32-.94.58a2.48 2.48 0 0 0-.58.94l-.43 1.24a.89.89 0 0 1-.35.47a1 1 0 0 1-.56.18a1 1 0 0 1-.57-.19a1 1 0 0 1-.34-.47l-.41-1.25a2.44 2.44 0 0 0-.58-.93a2.22 2.22 0 0 0-.93-.58l-1.25-.42a.93.93 0 0 1-.48-.35a1 1 0 0 1 .48-1.47l1.25-.41a2.49 2.49 0 0 0 1.53-1.53l.41-1.23a1 1 0 0 1 .32-.47a1 1 0 0 1 .55-.2a1 1 0 0 1 .57.16a1 1 0 0 1 .37.46l.42 1.28a2.49 2.49 0 0 0 1.53 1.53l1.25.43a.92.92 0 0 1 .46.35a.94.94 0 0 1 .18.56m5.789-5.36a1 1 0 0 1-.17.51a.82.82 0 0 1-.42.3l-.62.21a.84.84 0 0 0-.52.52l-.22.63a.929.929 0 0 1-.29.39a.82.82 0 0 1-.52.18a1.08 1.08 0 0 1-.49-.15a.92.92 0 0 1-.32-.44l-.21-.62a.719.719 0 0 0-.2-.32a.76.76 0 0 0-.32-.2l-.62-.2a1 1 0 0 1-.42-.31a.88.88 0 0 1-.16-.51a.94.94 0 0 1 .17-.51a.88.88 0 0 1 .42-.3l.61-.2a.91.91 0 0 0 .33-.2a.939.939 0 0 0 .2-.33l.21-.62c.06-.155.155-.292.28-.4a1 1 0 0 1 .49-.19a.94.94 0 0 1 .53.16a1 1 0 0 1 .32.41l.21.64a.942.942 0 0 0 .2.33a1 1 0 0 0 .32.2l.63.21a1 1 0 0 1 .41.3a.87.87 0 0 1 .17.51"/>
            </svg>
            <h3>Texturing</h3>
            {hoveredService === 'Texturing' && (
              <ul className="service-details">
                <li>Texture Creation</li>
                <li>UV Mapping</li>
                <li>Material Application</li>
              </ul>
            )}
          </div>
          <div
            className="service-item"
            onMouseEnter={() => setHoveredService('Rendering')}
            onMouseLeave={() => setHoveredService(null)}
          >
            <i></i>
            <svg xmlns="http://www.w3.org/2000/svg" width="undefined" height="undefined" viewBox="0 0 24 24" className="rendering_icon">
              <path fill="currentColor" d="m10.272 17.438l-4.37 2.504a10.064 10.064 0 0 1-2.728-3.207A10.029 10.029 0 0 1 2 12.697l4.856 2.781h.06zm5.581-1.238v5.038a10.101 10.101 0 0 1-8.312-.277l4.787-2.732h.09zm6.087-2.938a10.012 10.012 0 0 1-1.419 3.998a10.05 10.05 0 0 1-2.95 3.057v-9.54zm.06-1.94L17.144 8.57l-.209-.09l-3.207-1.87l4.37-2.503a10.004 10.004 0 0 1 2.727 3.19A9.97 9.97 0 0 1 22 11.322m-5.571-8.273l-4.786 2.77h-.08L8.147 7.8V2.762a10.13 10.13 0 0 1 8.282.257zm-10 .663v9.54l-4.37-2.494a10.022 10.022 0 0 1 1.423-3.993a10.06 10.06 0 0 1 2.947-3.053m9.424 6.096v4.423L12 16.448l-3.853-2.217V9.808L12 7.59z"/>
            </svg>
            <h3>Rendering</h3>
            {hoveredService === 'Rendering' && (
              <ul className="service-details">
                <li>Real-time Rendering</li>
                <li>Offline Rendering</li>
              </ul>
            )}
          </div>
        </section>
        <section className="testimonials_container">
          <div className="testimonial-item">Testimonial</div>
          <div className="testimonial-item">Testimonial</div>
          <div className="testimonial-item">Testimonial</div>
          <div className="testimonial-item">Testimonial</div>
        </section>
      </div>
    </div>
  );
};

export default Services;
