import React, { useState, useEffect } from 'react';
import { scroller } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import '../styles/Header.scss';
import { useTranslation } from 'react-i18next';

const Header: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [activeLang, setActiveLang] = useState(i18n.language);
  const SCROLL_OFFSET = -130;
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleLanguageChange = () => {
      setActiveLang(i18n.language);
    };

    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const scrollToTop = () => {
    scroller.scrollTo('home', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: SCROLL_OFFSET
    });
    if (isMobile) {
      setMenuOpen(false);
    }
  };

  const scrollToSection = (id: string, offset: number = SCROLL_OFFSET) => {
    scroller.scrollTo(id, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: offset
    });
    if (isMobile) {
      setMenuOpen(false);
    }
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const isTermsPage = location.pathname === '/terms';

  return (
    <header className='main_header'>
      <nav>
        <section className='container_01'>
          <a href="/" onClick={scrollToTop}></a>
          <h1 onClick={scrollToTop}>NeoDev3D</h1>
        </section>
        <div className={`container_02 ${menuOpen ? 'open' : ''}`}>
          <button onClick={() => scrollToSection('about')} disabled={isTermsPage}>
            {t('about_me')}
          </button>
          {!isTermsPage && <p>|</p>}
          <button onClick={() => scrollToSection('portfolio')} disabled={isTermsPage}>
            {t('portfolio')}
          </button>
          {!isTermsPage && <p>|</p>}
          <button onClick={() => scrollToSection('services')} disabled={isTermsPage}>
            {t('services')}
          </button>
          {!isTermsPage && <p>|</p>}
          <button onClick={() => scrollToSection('contact')} id='contact_btn' disabled={isTermsPage}>
            {t('contact')}
          </button>
          {isMobile && (
            <div className='language_selector_mobile'>
              <button
                onClick={() => changeLanguage('en')}
                className={activeLang === 'en' ? 'is_active' : ''}
                id='en_btn'
              >
              </button>
              <button
                onClick={() => changeLanguage('fr')}
                className={activeLang === 'fr' ? 'is_active' : ''}
                id='fr_btn'
              >
              </button>
            </div>
          )}
        </div>
        <section className='menu_toggle' onClick={toggleMenu}>
          <div className='menu_icon'></div>
          <div className='menu_icon'></div>
          <div className='menu_icon'></div>
        </section>
      </nav>
      {!isMobile && (
        <div className='language_selector'>
          <button
            onClick={() => changeLanguage('en')}
            className={activeLang === 'en' ? 'is_active' : ''}
            id='en_btn'
          >
          </button>
          <button
            onClick={() => changeLanguage('fr')}
            className={activeLang === 'fr' ? 'is_active' : ''}
            id='fr_btn'
          >
          </button>
        </div>
      )}
    </header>
  );
};

export default Header;
