// src/redux/reducers/contactReducer.ts
import { SEND_MESSAGE_REQUEST, SEND_MESSAGE_SUCCESS, SEND_MESSAGE_FAILURE, FETCH_MESSAGES_REQUEST, FETCH_MESSAGES_SUCCESS, FETCH_MESSAGES_FAILURE, DELETE_MESSAGE_REQUEST, DELETE_MESSAGE_SUCCESS, DELETE_MESSAGE_FAILURE } from '../actions/contactActions';

interface ContactState {
    loading: boolean;
    success: boolean;
    error: string | null;
    messages: any[];
}

const initialState: ContactState = {
    loading: false,
    success: false,
    error: null,
    messages: []
};

const contactReducer = (state = initialState, action: any): ContactState => {
    switch (action.type) {
        case SEND_MESSAGE_REQUEST:
            return { ...state, loading: true, success: false, error: null };
        case SEND_MESSAGE_SUCCESS:
            return { ...state, loading: false, success: true, error: null };
        case SEND_MESSAGE_FAILURE:
            return { ...state, loading: false, success: false, error: action.payload };
        case FETCH_MESSAGES_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_MESSAGES_SUCCESS:
            return { ...state, loading: false, messages: action.payload, error: null };
        case FETCH_MESSAGES_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case DELETE_MESSAGE_REQUEST:
            return { ...state, loading: true, error: null };
        case DELETE_MESSAGE_SUCCESS:
            return { 
                ...state, 
                loading: false, 
                messages: state.messages.filter(message => message.id !== action.payload), 
                error: null 
            };
        case DELETE_MESSAGE_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default contactReducer;