import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSkills } from '../redux/actions/skillActions';
import '../styles/About.scss';
import { Link, scroller } from 'react-scroll';
import { useTranslation } from 'react-i18next';

const About: React.FC = () => {
    const dispatch = useDispatch();
    const skills = useSelector((state: any) => state.skills.skills);
    const [hoveredSkill, setHoveredSkill] = useState<{ id: number, description: string, top: number, left: number } | null>(null);

    useEffect(() => {
        dispatch(fetchSkills());
    }, [dispatch]);

    const devSkills = skills.filter((skill: any) => !skill.is_3d_skill);
    const threeDSkills = skills.filter((skill: any) => skill.is_3d_skill);

    const SCROLL_OFFSET = -130;

    const scrollToSection = (id: string, offset: number = SCROLL_OFFSET) => {
        scroller.scrollTo(id, {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: offset
        });
      };

      const { t } = useTranslation();

    const handleMouseEnter = (id: number, description: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const target = event.currentTarget;
        const rect = target.getBoundingClientRect();
        const parentRect = target.closest('.about_skills')?.getBoundingClientRect();
        const top = rect.top - (parentRect ? parentRect.top : 0) + rect.height;
        const left = rect.left - (parentRect ? parentRect.left : 0) + rect.width / 2;

        setHoveredSkill({
            id,
            description,
            top,
            left
        });
    };

    return (
        <section className='about_section' id='about'>
            <h2>{t('about_me')}</h2>
            <div className='about_text'>
            <p>{t('about_paragraph1')}</p>
            <br />
            <p>{t('about_paragraph2')}</p>
            <br />
            <p>{t('about_paragraph3')}</p>
            <br />
            <p>{t('about_paragraph4')}</p>
            <br />
            <p>{t('about_paragraph5')}</p>
            <br />
            <p>{t('about_paragraph6')}</p>
            </div>
            <h3>Skills</h3>
            <div className='about_skills'>
                <section className='dev_skills'>
                    <h4>Development</h4>
                    {devSkills.map((skill: any) => (
                        <div 
                            key={skill.id} 
                            className='skill' 
                            onMouseEnter={(event) => handleMouseEnter(skill.id, skill.description, event)} 
                            onMouseLeave={() => setHoveredSkill(null)}
                        >
                            <p>{skill.name}</p>
                        </div>
                    ))}
                </section>
                <section className='others_skills'>
                    <h4>3D</h4>
                    {threeDSkills.map((skill: any) => (
                        <div 
                            key={skill.id} 
                            className='skill' 
                            onMouseEnter={(event) => handleMouseEnter(skill.id, skill.description, event)} 
                            onMouseLeave={() => setHoveredSkill(null)}
                        >
                            <p>{skill.name}</p>
                        </div>
                    ))}
                </section>
                {hoveredSkill && (
                    <div 
                        className='description' 
                        style={{ top: hoveredSkill.top + 3, left: hoveredSkill.left, transform: 'translateX(-50%)' }}
                    >
                        {hoveredSkill.description}
                    </div>
                )}
            </div>
        </section>
    );
};

export default About;
