// src/components/FpsView.tsx
import React, { Suspense, useEffect, useState, useRef, useCallback } from 'react';
import { Canvas, useFrame, extend } from '@react-three/fiber';
import { PointerLockControls, Loader } from '@react-three/drei';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { Physics, useBox, usePlane } from '@react-three/cannon';
import { Mesh, BoxGeometry, PlaneGeometry, MeshStandardMaterial, Vector3 } from 'three';
import '../styles/ThreeView.scss';

extend({ BoxGeometry, PlaneGeometry, MeshStandardMaterial });

interface ModelFBXProps {
    filePath: string;
    scale?: number;
    position?: [number, number, number];
}

function ModelFBX({ filePath, scale = 1, position = [0, 0, 0] }: ModelFBXProps) {
    const fbx = useFBXLoader(filePath);
    
    if (!fbx) {
        return null;  // Ou un fallback de chargement, comme une animation de chargement
    }

    return <primitive object={fbx} scale={scale} position={position} />;
}

const useFBXLoader = (filePath: string) => {
    const [fbx, setFbx] = useState<any>(null);

    useEffect(() => {
        const loader = new FBXLoader();
        loader.load(filePath, (loadedFbx) => {
            setFbx(loadedFbx);
        });
    }, [filePath]);

    return fbx;
};

const Player: React.FC = () => {
    const ref = useRef<Mesh>(null);
    const [playerRef, api] = useBox(() => ({ mass: 1, position: [-20, 20, 0], args: [1, 20, 1] }), ref);
    const velocity = useRef(new Vector3(0, 0, 0));
    const direction = useRef(new Vector3());

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        switch (event.code) {
            case 'KeyW':
                velocity.current.z = -1000;
                break;
            case 'KeyS':
                velocity.current.z = 1000;
                break;
            case 'KeyA':
                velocity.current.x = -1000;
                break;
            case 'KeyD':
                velocity.current.x = 1000;
                break;
            default:
                break;
        }
    }, []);

    const handleKeyUp = useCallback((event: KeyboardEvent) => {
        switch (event.code) {
            case 'KeyW':
            case 'KeyS':
                velocity.current.z = 0;
                break;
            case 'KeyA':
            case 'KeyD':
                velocity.current.x = 0;
                break;
            default:
                break;
        }
    }, []);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [handleKeyDown, handleKeyUp]);

    useFrame((state) => {
        if (ref.current) {
            state.camera.getWorldDirection(direction.current);
            direction.current.y = 0; // Keep horizontal movement only
            direction.current.normalize().multiplyScalar(velocity.current.z);
            direction.current.add(new Vector3().setFromMatrixColumn(state.camera.matrix, 0).multiplyScalar(velocity.current.x));

            api.velocity.set(direction.current.x, 0, direction.current.z);
            api.position.set(state.camera.position.x, state.camera.position.y, state.camera.position.z);
        }
    });

    return (
        <mesh ref={ref} visible={false}>
            <boxGeometry args={[1, 20, 1]} />
            <meshStandardMaterial color="blue" />
        </mesh>
    );
};

const Floor: React.FC = () => {
    usePlane(() => ({ rotation: [-Math.PI / 2, 0, 0], position: [100, 0, 0] }));
    return (
        <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -1, 0]}>
            <planeGeometry args={[500, 500]} />
            <meshStandardMaterial color="gray" />
        </mesh>
    );
};

interface ThreeViewProps {
    modelPath: string;
}

const FpsView: React.FC<ThreeViewProps> = ({ modelPath }) => {
    return (
        <div className="three-view-container">
            <div className="three-view-background">
                <Canvas camera={{ position: [-20, 10, 5], fov: 70 }} shadows className='three-view-canvas'>
                    <Physics>
                        <ambientLight intensity={0.8} />
                        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} castShadow />
                        <directionalLight position={[5, 10, 7.5]} intensity={1.6} castShadow />
                        <Suspense fallback={null}>
                            <ModelFBX filePath={modelPath} scale={0.1} position={[0, 0, 0]} />
                        </Suspense>
                        <Player />
                        <Floor />
                    </Physics>
                    <PointerLockControls />
                </Canvas>
                <Loader />
            </div>
        </div>
    );
};

export default FpsView;
