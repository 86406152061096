// src/components/Portfolio.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPublicProjects } from '../redux/actions/portfolioActions';
import ProjectCard from './ProjectCard';
import ProjectModal from './ProjectModal';
import { RootState } from '../redux/store';
import { Project } from '../redux/types/types';
import '../styles/Portfolio.scss';

const Portfolio: React.FC = () => {
    const dispatch = useDispatch();
    const portfolio = useSelector((state: RootState) => state.portfolio);
    const [selectedProject, setSelectedProject] = useState<Project | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchPublicProjects());
    }, [dispatch]);

    useEffect(() => {
        if (isModalOpen) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [isModalOpen]);

    const handleProjectClick = (project: Project) => {
        setSelectedProject(project);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedProject(null);
    };

    const webProjects = portfolio.projects.filter(project => !project.model);
    const threeDProjects = portfolio.projects.filter(project => project.model);

    return (
        <section className='portfolio_section' id='portfolio'>
            <h2>Portfolio</h2>
            <div className='projects_container'>
                {portfolio.loading ? (
                    <p>Loading...</p>
                ) : portfolio.error ? (
                    <p>{portfolio.error}</p>
                ) : (
                    <>
                        <div className="projects_web">
                            <h3>Web Projects</h3>
                            <div className="portfolio-grid">
                                {webProjects.map((project: Project) => (
                                    <ProjectCard key={project.id} project={project} onClick={handleProjectClick} />
                                ))}
                            </div>
                        </div>
                        <div className="projects_3d">
                            <h3>3D Projects</h3>
                            <div className="portfolio-grid">
                                {threeDProjects.map((project: Project) => (
                                    <ProjectCard key={project.id} project={project} onClick={handleProjectClick} />
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
            {isModalOpen && selectedProject && (
                <ProjectModal project={selectedProject} onClose={handleCloseModal} />
            )}
        </section>
    );
};

export default Portfolio;