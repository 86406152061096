import React from "react";

export default function SitePreview() {

    return (
        <div className="site-preview">
        <header className="site-header">
            <h1>Site Preview</h1>
        </header>
        <section className="preview">
            <div className="site-content">
            <h2>Site Content</h2>
            <p>Preview of the site content</p>
            </div>
            <div className="site-image">
            <img src="https://via.placeholder.com/150" alt="site preview" />
            </div>
        </section>
        </div>
    );
    }

