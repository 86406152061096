import axios from '../../axiosConfig'
import {
    FETCH_PROJECTS_REQUEST,
    FETCH_PROJECTS_SUCCESS,
    FETCH_PROJECTS_FAILURE,
    ADD_PROJECT,
    UPDATE_PROJECT,
    DELETE_PROJECT,
    PortfolioActionTypes
} from './portfolioActionsTypes';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchProjectsRequest = () => ({
    type: FETCH_PROJECTS_REQUEST
});

export const fetchProjectsSuccess = (projects: any[]) => ({
    type: FETCH_PROJECTS_SUCCESS,
    payload: projects
});

export const fetchProjectsFailure = (error: string) => ({
    type: FETCH_PROJECTS_FAILURE,
    payload: error
});

export const addProjectSuccess = (project: any) => ({
    type: ADD_PROJECT,
    payload: project
});

export const updateProjectSuccess = (project: any) => ({
    type: UPDATE_PROJECT,
    payload: project
});

export const deleteProjectSuccess = (projectId: string) => ({
    type: DELETE_PROJECT,
    payload: projectId
});

export const fetchProjects = () => {
    return async (dispatch: any) => {
        dispatch(fetchProjectsRequest());
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await axios.get(`${API_URL}/projects/user`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            });
            dispatch(fetchProjectsSuccess(response.data));
        } catch (error: any) {
            dispatch(fetchProjectsFailure(error.message));
        }
    };
};

export const fetchPublicProjects = () => {
    return async (dispatch: any) => {
        dispatch(fetchProjectsRequest());
        try {
            const response = await axios.get(`${API_URL}/public/portfolio`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            });
            dispatch(fetchProjectsSuccess(response.data));
        } catch (error: any) {
            dispatch(fetchProjectsFailure(error.message));
        }
    };
};

export const fetchPublicProjectsById = (projectId: string) => {
    return async (dispatch: any) => {
        dispatch(fetchProjectsRequest());
        try {
            const response = await axios.get(`${API_URL}/public/portfolio/${projectId}`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            });
            dispatch(fetchProjectsSuccess(response.data));
        } catch (error: any) {
            dispatch(fetchProjectsFailure(error.message));
        }
    };
}

export const addProject = (projectData: any) => {
    return async (dispatch: any) => {
        try {
            const response = await axios.post(`${API_URL}/projects/add`, projectData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                withCredentials: true,
            });
            dispatch(addProjectSuccess(response.data));
        } catch (error: any) {
            console.error('Error adding project', error);
        }
    };
};

export const updateProject = (projectId: string, projectData: any) => {
    return async (dispatch: any) => {
        try {
            const response = await axios.put(`${API_URL}/projects/${projectId}`, projectData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });
            dispatch(updateProjectSuccess(response.data));
        } catch (error: any) {
            console.error('Error updating project', error);
        }
    };
};

export const deleteProject = (projectId: string) => {
    return async (dispatch: any) => {
        try {
            await axios.delete(`${API_URL}/projects/${projectId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    withCredentials: true,
                },
            });
            dispatch(deleteProjectSuccess(projectId));
        } catch (error: any) {
            console.error('Error deleting project', error);
        }
    };
};