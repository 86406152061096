import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import '../styles/Base.scss';
import '../styles/Terms.scss';
import { useTranslation } from 'react-i18next';

const MIN_SPEED = 1;
const MAX_SPEED = 2.5;

function randomNumber(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

class Blob {
  el: HTMLElement;
  size: number;
  initialX: number;
  initialY: number;
  vx: number;
  vy: number;
  x: number;
  y: number;

  constructor(el: HTMLElement) {
    this.el = el;
    const boundingRect = this.el.getBoundingClientRect();
    this.size = boundingRect.width;
    this.initialX = randomNumber(0, window.innerWidth - this.size);
    this.initialY = randomNumber(0, window.innerHeight - this.size);
    this.el.style.top = `${this.initialY}px`;
    this.el.style.left = `${this.initialX}px`;
    this.vx = randomNumber(MIN_SPEED, MAX_SPEED) * (Math.random() > 0.5 ? 1 : -1);
    this.vy = randomNumber(MIN_SPEED, MAX_SPEED) * (Math.random() > 0.5 ? 1 : -1);
    this.x = this.initialX;
    this.y = this.initialY;
  }

  update() {
    this.x += this.vx;
    this.y += this.vy;
    if (this.x >= window.innerWidth - this.size) {
      this.x = window.innerWidth - this.size;
      this.vx *= -1;
    }
    if (this.y >= window.innerHeight - this.size) {
      this.y = window.innerHeight - this.size;
      this.vy *= -1;
    }
    if (this.x <= 0) {
      this.x = 0;
      this.vx *= -1;
    }
    if (this.y <= 0) {
      this.y = 0;
      this.vy *= -1;
    }
  }

  move() {
    this.el.style.transform = `translate(${this.x - this.initialX}px, ${this.y - this.initialY}px)`;
  }
}

function initBlobs() {
  const blobEls = document.querySelectorAll('.bouncing-blob');
  const blobs = Array.from(blobEls).map((blobEl) => new Blob(blobEl as HTMLElement));

  function update() {
    requestAnimationFrame(update);
    blobs.forEach((blob) => {
      blob.update();
      blob.move();
    });
  }

  requestAnimationFrame(update);
}

const Terms: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    initBlobs();
  }, []);

  function handleBackHome() {
    window.location.href = '/';
  };

  return (
    <section className='terms_page'>
      <div className="bouncing-blobs-container">
        <div className="bouncing-blobs-glass"></div>
        <div className="bouncing-blobs">
          <div className="bouncing-blob bouncing-blob--blue"></div>
          <div className="bouncing-blob bouncing-blob--blue"></div>
          <div className="bouncing-blob bouncing-blob--blue"></div>
          <div className="bouncing-blob bouncing-blob--white"></div>
          <div className="bouncing-blob bouncing-blob--purple"></div>
          <div className="bouncing-blob bouncing-blob--purple"></div>
          <div className="bouncing-blob bouncing-blob--pink"></div>
        </div>
      </div>
      <Header />
      <button onClick={handleBackHome} className='back_btn'>{t('terms_btn')}</button>
      <div className='terms'>
        <h1>{t('terms_title')}</h1>
        <h2>{t('terms_editor')}</h2>
        <p>{t('terms_editor_content')}</p>
        <p>{t('terms_design_content')} <a href="https://neodev3d.com">https://neodev3d.com</a></p>
        <h2>{t('terms_hosting')}</h2>
        <p>{t('terms_vercel')} <a target='blank' href="https://vercel.com">https://vercel.com</a></p>
        <h2>{t('terms_services')}</h2>
        <p>{t('terms_services_content')}</p>
        <h2>{t('terms_intellectual_property')}</h2>
        <p>{t('terms_ip_content')} <a href="mailto:contact@neodev3d.com">contact@neodev3d.com</a></p>
        <p>{t('terms_ip_content_2')}</p>
        <h2>{t('terms_hyperlinks')}</h2>
        <p>{t('terms_hyperlinks_content')}</p>
        <h2>{t('terms_cookies')}</h2>
        <p>{t('terms_cookies_content')}</p>
        <p>{t('terms_cookies_content_2')}<a target='blank' href="https://cnil.fr">https://cnil.fr</a></p>
        <p>{t('terms_cookies_content_3')}</p>
        <h2>{t('terms_personal_data')}</h2>
        <p>{t('terms_personal_data_content')}</p>
        <p>{t('terms_personal_data_content_2')}</p>
        <p>{t('terms_personal_data_content_3')}</p>
        <p>{t('terms_personal_data_content_4')} <a href="mailto:contact@neodev3d.com">contact@neodev3d.com</a></p>
        <p>{t('terms_personal_data_content_5')}</p>
        <p>{t('terms_personal_data_content_6')}</p>
        <p>{t('terms_personal_data_content_7')}</p>
      </div>
    </section>
  );
};

export default Terms;
