import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DashBoard from './Dashboard';
import SitePreview from './SitePreview';
import AdminUpdateInfo from './AdminUpdateInfo';
import PortfolioManagement from './PortfolioManagement';
import ContactManagement from './ContactManagement';
import TestimonialsManagement from './TestimonialsManagement';
import '../../styles/AdminPanel.scss';

const MIN_SPEED = 1;
const MAX_SPEED = 2;

function randomNumber(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

class Blob {
  el: HTMLElement;
  size: number;
  initialX: number;
  initialY: number;
  vx: number;
  vy: number;
  x: number;
  y: number;

  constructor(el: HTMLElement) {
    this.el = el;
    const boundingRect = this.el.getBoundingClientRect();
    this.size = boundingRect.width;
    this.initialX = randomNumber(0, window.innerWidth - this.size);
    this.initialY = randomNumber(0, window.innerHeight - this.size);
    this.el.style.top = `${this.initialY}px`;
    this.el.style.left = `${this.initialX}px`;
    this.vx = randomNumber(MIN_SPEED, MAX_SPEED) * (Math.random() > 0.5 ? 1 : -1);
    this.vy = randomNumber(MIN_SPEED, MAX_SPEED) * (Math.random() > 0.5 ? 1 : -1);
    this.x = this.initialX;
    this.y = this.initialY;
  }

  update() {
    this.x += this.vx;
    this.y += this.vy;
    if (this.x >= window.innerWidth - this.size) {
      this.x = window.innerWidth - this.size;
      this.vx *= -1;
    }
    if (this.y >= window.innerHeight - this.size) {
      this.y = window.innerHeight - this.size;
      this.vy *= -1;
    }
    if (this.x <= 0) {
      this.x = 0;
      this.vx *= -1;
    }
    if (this.y <= 0) {
      this.y = 0;
      this.vy *= -1;
    }
  }

  move() {
    this.el.style.transform = `translate(${this.x - this.initialX}px, ${this.y - this.initialY}px)`;
  }
}

function initBlobs() {
  const blobEls = document.querySelectorAll('.bouncing-blob');
  const blobs = Array.from(blobEls).map((blobEl) => new Blob(blobEl as HTMLElement));

  function update() {
    requestAnimationFrame(update);
    blobs.forEach((blob) => {
      blob.update();
      blob.move();
    });
  }

  requestAnimationFrame(update);
}

const AdminPanel: React.FC = () => {
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState<string>('dashboard');

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  const renderPage = () => {
    switch (activePage) {
      case 'dashboard':
        return <DashBoard />;
      case 'site-preview':
        return <SitePreview />;
      case 'update-info':
        return <AdminUpdateInfo />;
      case 'portfolio-management':
        return <PortfolioManagement />;
      case 'contact-management':
        return <ContactManagement />;
      case 'testimonials-management':
        return <TestimonialsManagement />;
      default:
        return <DashBoard />;
    }
  };

  useEffect(() => {
    initBlobs();
  }, []);

  return (
    <React.Fragment>
      <div className="bouncing-blobs-container">
        <div className="bouncing-blobs-glass"></div>
        <div className="bouncing-blobs">
          <div className="bouncing-blob bouncing-blob--blue"></div>
          <div className="bouncing-blob bouncing-blob--blue"></div>
          <div className="bouncing-blob bouncing-blob--blue"></div>
          <div className="bouncing-blob bouncing-blob--white"></div>
          <div className="bouncing-blob bouncing-blob--purple"></div>
          <div className="bouncing-blob bouncing-blob--purple"></div>
          <div className="bouncing-blob bouncing-blob--pink"></div>
        </div>
      </div>
      <div className="admin-panel-container">
        <main>
          <aside className="sidebar">
            <div className="profile">
              <img src="https://www.neodev3d.com/logo512.png" alt="Profile" />
              <h2>Bienvenue Arthur</h2>
            </div>
            <nav className="menu">
              <ul>
                <li><button className={activePage === 'dashboard' ? 'active' : ''} onClick={() => setActivePage('dashboard')}>Tableau de bord</button></li>
                <li><button className={activePage === 'site-preview' ? 'active' : ''} onClick={() => setActivePage('site-preview')}>Aperçu du site</button></li>
                <li><button className={activePage === 'update-info' ? 'active' : ''} onClick={() => setActivePage('update-info')}>Mise à jour des infos</button></li>
                <li><button className={activePage === 'portfolio-management' ? 'active' : ''} onClick={() => setActivePage('portfolio-management')}>Gestion du portfolio</button></li>
                <li><button className={activePage === 'contact-management' ? 'active' : ''} onClick={() => setActivePage('contact-management')}>Gestion des contacts</button></li>
                <li><button className={activePage === 'testimonials-management' ? 'active' : ''} onClick={() => setActivePage('testimonials-management')}>Gestion des témoignages</button></li>
              </ul>
            </nav>
          </aside>
          <section>
            <div className='title'>
              <h1>{activePage === 'dashboard' ? 'Tableau de bord' : 
                    activePage === 'site-preview' ? 'Aperçu du site' :
                    activePage === 'update-info' ? 'Mise à jour des infos' :
                    activePage === 'portfolio-management' ? 'Gestion du portfolio' :
                    activePage === 'contact-management' ? 'Gestion des contacts' :
                    'Gestion des témoignages'}</h1>
              <button onClick={handleLogout} className='log_out'>Se déconnecter</button>
            </div>
            <div className='rendered_page'>
              {renderPage()}
            </div>
          </section>
        </main>
      </div>
    </React.Fragment>
  );
};

export default AdminPanel;
