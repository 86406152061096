// src/redux/reducers/skillReducer.ts
import { FETCH_SKILLS_REQUEST, FETCH_SKILLS_SUCCESS, FETCH_SKILLS_FAILURE, CREATE_SKILL_REQUEST, CREATE_SKILL_SUCCESS, CREATE_SKILL_FAILURE, UPDATE_SKILL_REQUEST, UPDATE_SKILL_SUCCESS, UPDATE_SKILL_FAILURE } from '../actions/skillActions';

interface SkillState {
    loading: boolean;
    skills: any[];
    error: string | null;
}

const initialState: SkillState = {
    loading: false,
    skills: [],
    error: null
};

const skillReducer = (state = initialState, action: any): SkillState => {
    switch (action.type) {
        case FETCH_SKILLS_REQUEST:
        case CREATE_SKILL_REQUEST:
        case UPDATE_SKILL_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_SKILLS_SUCCESS:
            return { ...state, loading: false, skills: action.payload, error: null };
        case CREATE_SKILL_SUCCESS:
        case UPDATE_SKILL_SUCCESS:
            return { ...state, loading: false, error: null };
        case FETCH_SKILLS_FAILURE:
        case CREATE_SKILL_FAILURE:
        case UPDATE_SKILL_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default skillReducer;
