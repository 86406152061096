// src/redux/actions/contactActions.ts
import axios from 'axios';
import { Dispatch } from 'redux';

export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE';

export const FETCH_MESSAGES_REQUEST = 'FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_FAILURE = 'FETCH_MESSAGES_FAILURE';

export const DELETE_MESSAGE_REQUEST = 'DELETE_MESSAGE_REQUEST';
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export const DELETE_MESSAGE_FAILURE = 'DELETE_MESSAGE_FAILURE';

const API_URL = process.env.REACT_APP_API_URL;

export const sendMessage = (messageData: any) => {
    return async (dispatch: Dispatch) => {
        dispatch({ type: SEND_MESSAGE_REQUEST });
        try {
            const response = await axios.post(`${API_URL}/public/contact`, messageData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            dispatch({ type: SEND_MESSAGE_SUCCESS, payload: response.data });
        } catch (error: any) {
            dispatch({ type: SEND_MESSAGE_FAILURE, payload: error.message });
        }
    };
};

export const fetchMessages = () => {
    return async (dispatch: Dispatch) => {
        dispatch({ type: FETCH_MESSAGES_REQUEST });
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${API_URL}/admin/messages`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            dispatch({ type: FETCH_MESSAGES_SUCCESS, payload: response.data });
        } catch (error: any) {
            dispatch({ type: FETCH_MESSAGES_FAILURE, payload: error.message });
        }
    };
};

export const deleteMessage = (messageId: string) => {
    return async (dispatch: Dispatch) => {
        dispatch({ type: DELETE_MESSAGE_REQUEST });
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`${API_URL}/admin/messages/${messageId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            dispatch({ type: DELETE_MESSAGE_SUCCESS, payload: messageId });
        } catch (error: any) {
            dispatch({ type: DELETE_MESSAGE_FAILURE, payload: error.message });
        }
    };
};
