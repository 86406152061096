// src/components/AdminProjectForm.tsx
import React, { useState, useEffect } from 'react';

interface ProjectFormProps {
    project?: any;
    onSubmit: (projectData: any) => void;
}

const AdminProjectForm: React.FC<ProjectFormProps> = ({ project, onSubmit }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [images, setImages] = useState<string[]>([]);
    const [model, setModel] = useState('');
    const [creationDate, setCreationDate] = useState('');

    useEffect(() => {
        if (project) {
            setTitle(project.title);
            setDescription(project.description);
            setImages(project.images || []);
            setModel(project.model);
            setCreationDate(project.creation_date ? new Date(project.creation_date).toISOString().split('T')[0] : '');
        }
    }, [project]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const projectData = { title, description, images, model, creation_date: creationDate };
        onSubmit(projectData);
    };

    const handleAddImage = () => {
        setImages([...images, '']);
    };

    const handleRemoveImage = (index: number) => {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
    };

    const handleImageChange = (index: number, value: string) => {
        const newImages = [...images];
        newImages[index] = value;
        setImages(newImages);
    };

    return (
        <form onSubmit={handleSubmit} className="project-form">
            <h3>{project ? 'Modifier le Projet' : 'Ajouter un Nouveau Projet'}</h3>
            <input
                type="text"
                placeholder="Titre"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
            />
            <textarea
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
            ></textarea>
            <div className="images-input">
                <h4>Images</h4>
                {images.map((image, index) => (
                    <div key={index} className="image-input">
                        <input
                            type="text"
                            placeholder={`Image URL ${index + 1}`}
                            value={image}
                            onChange={(e) => handleImageChange(index, e.target.value)}
                            required
                        />
                        <button type="button" onClick={() => handleRemoveImage(index)}>Remove</button>
                    </div>
                ))}
                <button type="button" onClick={handleAddImage}>Add Image</button>
            </div>
            <input
                type="text"
                placeholder="Modèle 3D"
                value={model}
                onChange={(e) => setModel(e.target.value)}
            />
            <h4>Date de création</h4>
            <input
                type="date"
                value={creationDate}
                onChange={(e) => setCreationDate(e.target.value)}
                required
            />
            <button type="submit">{project ? 'Mettre à jour' : 'Ajouter'}</button>
        </form>
    );
};

export default AdminProjectForm;