import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Popup3D = () => {
  const [isVisible, setIsVisible] = useState(true); // Gérer la visibilité de la popup
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClose = () => {
    setIsVisible(false); // Ferme la popup
  };

  const handleRedirect = () => {
    navigate('/3d_printing'); // Redirection vers la page d'impression 3D
  };

  if (!isVisible) {
    return null; // Ne pas afficher la popup si elle est fermée
  }

  return (
    <div className="popup">
      <div className="popup-content">
        <p>{t("3d_printing_popup_txt")}</p>
        <button className="popup-button" onClick={handleRedirect}>
            {t("3d_printing_popup_btn")}
        </button>
        <button className="popup-close" onClick={handleClose}>
          &times;
        </button>
      </div>
    </div>
  );
};

export default Popup3D;
