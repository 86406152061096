// portfolioActionsTypes.ts
export const FETCH_PROJECTS_REQUEST = 'FETCH_PROJECTS_REQUEST';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE';
export const ADD_PROJECT = 'ADD_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';

export interface FetchProjectsRequest {
    type: typeof FETCH_PROJECTS_REQUEST;
}

export interface FetchProjectsSuccess {
    type: typeof FETCH_PROJECTS_SUCCESS;
    payload: any[];
}

export interface FetchProjectsFailure {
    type: typeof FETCH_PROJECTS_FAILURE;
    error: string;
}

export type PortfolioActionTypes = FetchProjectsRequest | FetchProjectsSuccess | FetchProjectsFailure;