// src/components/AdminProjectItem.tsx
import React from 'react';

interface ProjectItemProps {
  project: any;
  onEdit: (project: any) => void;
  onDelete: (projectId: string) => void;
}

const truncateText = (text: string, wordLimit: number): string => {
  const words = text.split(' ');
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(' ') + '...';
  }
  return text;
};

const AdminProjectItem: React.FC<ProjectItemProps> = ({ project, onEdit, onDelete }) => {
  return (
    <div className="project-item">
      <h3>{project.title}</h3>
      <p>{truncateText(project.description, 10)}</p>
      {project.images && project.images.length > 0 && (
        <img src={project.images[0]} alt={project.title} />
      )}
      <button onClick={() => onEdit(project)}>Modifier</button>
      <button onClick={() => onDelete(project.id)}>Supprimer</button>
    </div>
  );
};

export default AdminProjectItem;
