// src/components/ThreeView.tsx
import React, { Suspense, useEffect, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Loader } from '@react-three/drei';
import '../styles/ThreeView.scss';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';

interface ModelFBXProps {
    filePath: string;
    scale?: number;
    position?: [number, number, number];
}

function ModelFBX({ filePath, scale = 1, position = [0, 0, 0] }: ModelFBXProps) {
    const fbx = useFBXLoader(filePath);
    
    if (!fbx) {
        return null;  // Ou un fallback de chargement, comme une animation de chargement
    }

    return <primitive object={fbx} scale={scale} position={position} />;
}

const useFBXLoader = (filePath: string) => {
    const [fbx, setFbx] = useState<any>(null);

    useEffect(() => {
        const loader = new FBXLoader();
        loader.load(filePath, (loadedFbx) => {
            setFbx(loadedFbx);
        });
    }, [filePath]);

    return fbx;
};

interface ThreeViewProps {
    modelPath: string;
}

const ThreeView: React.FC<ThreeViewProps> = ({ modelPath }) => {
    return (
        <div className="three-view-container">
            <div className="three-view-background">
                <Canvas camera={{ position: [30, 50, 10], fov:60 }} shadows className='three-view-canvas'>
                    {/* Lighting setup */}
                    <ambientLight intensity={0.8} />
                    <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} castShadow />
                    <directionalLight position={[5, 10, 7.5]} intensity={1.6} castShadow />
                    <Suspense fallback={null}>
                        <ModelFBX filePath={modelPath} scale={0.5} position={[0, -15, 0]} />
                    </Suspense>
                    <OrbitControls />
                </Canvas>
                <Loader />
            </div>
        </div>
    );
};

export default ThreeView;
