// src/components/AdminProjectModal.tsx
import React from 'react';
import ReactDOM from 'react-dom';
import '../../styles/PortfolioManagement.scss';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const AdminProjectModal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
    return ReactDOM.createPortal(
        <div className={`modal ${isOpen ? 'is-open' : ''}`}>
            <div className="admin_modal_content">
                <button className="modal-close" onClick={onClose}>×</button>
                {children}
            </div>
        </div>,
        document.body
    );
};

export default AdminProjectModal;
