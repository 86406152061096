// src/components/ContactManagement.tsx
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMessages } from '../../redux/actions/contactActions';
import { RootState } from '../../redux/store';
import AdminMessageItem from './AdminMessageItem';
import '../../styles/ContactManagement.scss';

const ContactManagement: React.FC = () => {
    const dispatch = useDispatch();
    const { messages, loading, error } = useSelector((state: RootState) => state.contact);

    useEffect(() => {
        dispatch(fetchMessages());
    }, [dispatch]);

    return (
        <div className="contact-management">
            <header className="contact-header">
                <h1>Contact Management</h1>
            </header>
            <section className="management">
                <div className="contact-content">
                    <h2>Contact Content</h2>
                    <p>Manage your contact content</p>
                    {loading && <p>Loading...</p>}
                    {error && <p>Error: {error}</p>}
                    {messages && messages.map((message: any) => (
                        <AdminMessageItem key={message.id} message={message} />
                    ))}
                </div>
            </section>
        </div>
    );
};

export default ContactManagement;
