import React from 'react';

import '../styles/Footer.scss';

import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
    const { t } = useTranslation();
    return (
        <footer className='main_footer'>
            <a target="_blank" href="https://www.linkedin.com/in/arthur-clapasson" rel="noreferrer" className='linkedin_logo'></a>
            <h4>© NeoDev3D</h4>
            <br />
            <div>{t('footer_txt')} <a className='terms' href="/terms">{t('footer_btn')}</a></div>
        </footer>
    );
};

export default Footer;
