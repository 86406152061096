// src/redux/actions/skillActions.ts
import axios from 'axios';
import { Dispatch } from 'redux';

export const FETCH_SKILLS_REQUEST = 'FETCH_SKILLS_REQUEST';
export const FETCH_SKILLS_SUCCESS = 'FETCH_SKILLS_SUCCESS';
export const FETCH_SKILLS_FAILURE = 'FETCH_SKILLS_FAILURE';
export const CREATE_SKILL_REQUEST = 'CREATE_SKILL_REQUEST';
export const CREATE_SKILL_SUCCESS = 'CREATE_SKILL_SUCCESS';
export const CREATE_SKILL_FAILURE = 'CREATE_SKILL_FAILURE';
export const UPDATE_SKILL_REQUEST = 'UPDATE_SKILL_REQUEST';
export const UPDATE_SKILL_SUCCESS = 'UPDATE_SKILL_SUCCESS';
export const UPDATE_SKILL_FAILURE = 'UPDATE_SKILL_FAILURE';
export const DELETE_SKILL_REQUEST = 'DELETE_SKILL_REQUEST';
export const DELETE_SKILL_SUCCESS = 'DELETE_SKILL_SUCCESS';
export const DELETE_SKILL_FAILURE = 'DELETE_SKILL_FAILURE';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchSkills = () => {
    return async (dispatch: Dispatch) => {
        dispatch({ type: FETCH_SKILLS_REQUEST });
        try {
            const response = await axios.get(`${API_URL}/public/skills`);
            dispatch({ type: FETCH_SKILLS_SUCCESS, payload: response.data });
        } catch (error: any) {
            dispatch({ type: FETCH_SKILLS_FAILURE, payload: error.message });
        }
    };
};

export const createSkill = (skillData: any) => {
    return async (dispatch: Dispatch) => {
        dispatch({ type: CREATE_SKILL_REQUEST });
        try {
            const response = await axios.post(`${API_URL}/admin/skills`, skillData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            dispatch({ type: CREATE_SKILL_SUCCESS, payload: response.data });
            dispatch(fetchSkills() as any);
        } catch (error: any) {
            dispatch({ type: CREATE_SKILL_FAILURE, payload: error.message });
        }
    };
};

export const updateSkill = (id: number, skillData: any) => {
    return async (dispatch: Dispatch) => {
        dispatch({ type: UPDATE_SKILL_REQUEST });
        try {
            const response = await axios.put(`${API_URL}/admin/skills/${id}`, skillData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            dispatch({ type: UPDATE_SKILL_SUCCESS, payload: response.data });
            dispatch(fetchSkills() as any);
        } catch (error: any) {
            dispatch({ type: UPDATE_SKILL_FAILURE, payload: error.message });
        }
    };
};

export const deleteSkill = (id: number) => {
    return async (dispatch: Dispatch) => {
        dispatch({ type: DELETE_SKILL_REQUEST });
        try {
            await axios.delete(`${API_URL}/admin/skills/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            dispatch({ type: DELETE_SKILL_SUCCESS, payload: id });
            dispatch(fetchSkills() as any);
        } catch (error: any) {
            dispatch({ type: DELETE_SKILL_FAILURE, payload: error.message });
        }
    };
};
