import { Dispatch } from 'redux';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

interface Credentials {
    email: string;
    password: string;
    recaptchaToken: string;
}

interface ErrorResponse {
    response?: {
        data?: {
            message?: string;
        };
    };
}

export const login = (credentials: Credentials) => async (dispatch: Dispatch) => {
    dispatch({ type: 'LOGIN_REQUEST' });
    try {
        const response = await axios.post(`${API_URL}/auth/login`, credentials, {
            withCredentials: true, // Inclut les cookies dans la requête
        });
        const { token } = response.data;
        localStorage.setItem('token', token);
        dispatch({ type: 'LOGIN_SUCCESS', payload: { token } });
    } catch (error) {
        let errorMessage = 'Login failed. Please try again.';
        if (isErrorResponse(error)) {
            errorMessage = error.response?.data?.message || errorMessage;
        }
        dispatch({ type: 'LOGIN_FAILURE', payload: errorMessage });
    }
};

const isErrorResponse = (error: unknown): error is ErrorResponse => {
    return typeof error === 'object' && error !== null && 'response' in error;
};

export const validateToken = () => async (dispatch: Dispatch) => {
    const token = localStorage.getItem('token');
    if (token) {
        try {
            const response = await axios.post(`${API_URL}/auth/validate-token`, { token }, {
                withCredentials: true,
            });
            if (response.data.valid) {
                dispatch({ type: 'LOGIN_SUCCESS', payload: { token } });
            } else {
                localStorage.removeItem('token');
                dispatch({ type: 'LOGIN_FAILURE', payload: 'Invalid token' });
            }
        } catch (error) {
            localStorage.removeItem('token');
            dispatch({ type: 'LOGIN_FAILURE', payload: 'Invalid token' });
        }
    } else {
        dispatch({ type: 'LOGIN_FAILURE', payload: 'No token found' });
    }
};

