import {
    FETCH_PROJECTS_REQUEST,
    FETCH_PROJECTS_SUCCESS,
    FETCH_PROJECTS_FAILURE,
    ADD_PROJECT,
    UPDATE_PROJECT,
    DELETE_PROJECT
} from '../actions/portfolioActionsTypes';
import { ProjectAction, ProjectState } from '../types/types';

const initialState: ProjectState = {
    loading: false,
    projects: [],
    error: ''
};

const portfolioReducer = (state = initialState, action: ProjectAction): ProjectState => {
    switch (action.type) {
        case FETCH_PROJECTS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_PROJECTS_SUCCESS:
            return {
                loading: false,
                projects: action.payload,
                error: ''
            };
        case FETCH_PROJECTS_FAILURE:
            return {
                loading: false,
                projects: [],
                error: action.payload
            };
        case ADD_PROJECT:
            return {
                ...state,
                projects: [...state.projects, action.payload]
            };
        case UPDATE_PROJECT:
            return {
                ...state,
                projects: state.projects.map(project =>
                    project.id === action.payload.id ? action.payload : project
                )
            };
        case DELETE_PROJECT:
            return {
                ...state,
                projects: state.projects.filter(project => project.id !== action.payload)
            };
        default:
            return state;
    }
};

export default portfolioReducer;
