// src/components/PortfolioManagement.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjects, addProject, updateProject, deleteProject } from '../../redux/actions/portfolioActions';
import AdminProjectForm from './AdminProjectForm';
import AdminProjectItem from './AdminProjectItem';
import AdminProjectModal from './AdminProjectModal';
import '../../styles/PortfolioManagement.scss';
import { RootState } from '../../redux/store';
import { Project } from '../../redux/types/types';

const PortfolioManagement: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedProject, setSelectedProject] = useState<Project | null>(null);
    const dispatch = useDispatch();
    const projects = useSelector((state: RootState) => state.portfolio.projects);

    useEffect(() => {
        dispatch(fetchProjects());
    }, [dispatch]);

    const handleEdit = (project: Project) => {
        setSelectedProject(project);
        setIsModalOpen(true);
    };

    const handleDelete = (projectId: string) => {
        dispatch(deleteProject(projectId));
    };

    const handleFormSubmit = (projectData: FormData) => {
        if (selectedProject) {
            dispatch(updateProject(selectedProject.id, projectData));
        } else {
            dispatch(addProject(projectData));
        }
        setIsModalOpen(false);
        setSelectedProject(null);
    };

    return (
        <div className="portfolio-management">
            <button onClick={() => setIsModalOpen(true)} className='add_project'>Ajouter un nouveau projet</button>
            <div className="project-list">
                {projects.map((project: Project) => (
                    <AdminProjectItem
                        key={project.id}
                        project={project}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                    />
                ))}
            </div>
            <AdminProjectModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <AdminProjectForm project={selectedProject} onSubmit={handleFormSubmit} />
            </AdminProjectModal>
        </div>
    );
};

export default PortfolioManagement;
