import React from "react";

const TestimonialsManagement = () => {
    return (
        <div className="testimonials-management">
        <header className="testimonials-header">
            <h1>Testimonials Management</h1>
        </header>
        <section className="management">
            <div className="testimonials-content">
            <h2>Testimonials Content</h2>
            <p>Manage your testimonials content</p>
            </div>
            <div className="testimonials-image">
            <img src="https://via.placeholder.com/150" alt="testimonials management" />
            </div>
        </section>
        </div>
    );
    }

export default TestimonialsManagement;