import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import "../styles/Printing.scss";

const Printing = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/', { state: { scrollToContact: true } });
  };

  return (
    <section className="printing_section" id="printing">
      <h2 className="printing_title">{t("printing_title")}</h2>
      <p className="printing_txt">{t("printing_txt")}</p>

      <h3 className="printing_our_reals">{t("printing_our_reals")}</h3>
      <div className="real_container">
        {/* Exemple d'images de réalisations */}
        <img src="/images/real1.jpg" alt="Realisation 1" className="real_img" />
        <img src="/images/real2.jpg" alt="Realisation 2" className="real_img" />
        <img src="/images/real3.jpg" alt="Realisation 3" className="real_img" />
      </div>

      <button className="contact_button" onClick={handleContactClick}>
        {t("contact_button_text")}
      </button>
    </section>
  );
};

export default Printing;
